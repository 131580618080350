import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { article as articleData } from "scmp-app/data";
import type { helpersCheckArticleIsInSectionsArticle$key } from "scmp-app/queries/__generated__/helpersCheckArticleIsInSectionsArticle.graphql";
import type { helpersCheckArticleRenderTypeArticle$key } from "scmp-app/queries/__generated__/helpersCheckArticleRenderTypeArticle.graphql";
import type { helpersCheckHasLiveTagArticle$key } from "scmp-app/queries/__generated__/helpersCheckHasLiveTagArticle.graphql";
import type { helpersGetArticleTypeArticle$key } from "scmp-app/queries/__generated__/helpersGetArticleTypeArticle.graphql";

import {
  checkCanShowInPlusPlatform,
  checkIsContainsPlusQueryString,
  checkIsPlusArticle,
} from "./plus/helpers";
import { checkIsPostiesArticle } from "./posties/helpers";
import { checkIsStyleArticle } from "./style-article/helper";
import { ArticleRenderType } from "./types";

export const getArticleType = (reference: helpersGetArticleTypeArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersGetArticleTypeArticle on Article @inline {
        types {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  const [articleType] = article.types?.flatMap(type => type?.value) ?? [];
  return articleType?.entityId;
};

export const checkArticleIsInSections = (
  reference: helpersCheckArticleIsInSectionsArticle$key,
  entityIds: string[],
) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckArticleIsInSectionsArticle on Article @inline {
        sections {
          value {
            entityId
          }
        }
      }
    `,
    reference,
  );
  const sections =
    article?.sections?.flatMap(
      section => section?.value?.map(value => value && value.entityId).filter(notEmpty) ?? [],
    ) ?? [];
  return entityIds.some(entityId => sections.includes(entityId));
};

export const checkHasLiveTag = (reference: helpersCheckHasLiveTagArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckHasLiveTagArticle on Article @inline {
        flattenTypeIds
        liveArticle {
          status
        }
      }
    `,
    reference,
  );
  const isLiveArticle =
    article.flattenTypeIds?.includes(articleData.types.live.entityUuid) ?? false;
  const isLive = article.liveArticle?.status ?? false;
  return isLiveArticle && isLive;
};

export const checkArticleRenderType = (
  reference: helpersCheckArticleRenderTypeArticle$key,
  query: Record<string, string | string[] | undefined>,
) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckArticleRenderTypeArticle on Article @inline {
        ...helpersCheckCanShowInPlusPlatformArticle
        ...helpersCheckIsPlusArticle
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsStyleArticle
        ...helpersGetArticleTypeArticle
      }
    `,
    reference,
  );

  if (checkIsPostiesArticle(article)) return ArticleRenderType.Posties;
  if (checkIsStyleArticle(article)) return ArticleRenderType.Style;
  if (
    checkIsPlusArticle(article) ||
    (checkCanShowInPlusPlatform(article) && checkIsContainsPlusQueryString(query))
  )
    return ArticleRenderType.Plus;

  const articleType = getArticleType(article);

  switch (articleType) {
    case articleData.types.newsletter.entityId:
      return ArticleRenderType.Newsletter;
    case articleData.types.studio.entityId:
      return ArticleRenderType.MorningStudio;
    case articleData.types.longRead.entityId:
      return ArticleRenderType.LongRead;
    case articleData.types.photoEssay.entityId:
      return ArticleRenderType.PhotoEssay;
    case articleData.types.live.entityId:
      return ArticleRenderType.Live;
    case articleData.types.infographic.entityId:
      return ArticleRenderType.Infographic;
    case articleData.types.infographicLongRead.entityId:
      return ArticleRenderType.InfographicLongRead;
    case articleData.types.series.entityId:
      return ArticleRenderType.Series;
    default:
      return ArticleRenderType.Generic;
  }
};
